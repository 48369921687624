import React from 'react';
import { graphql } from "gatsby";

import LayoutBlog from '../components/LayoutBlog';
import Hero from '../components/sections/Hero';
import PostViewer from '../components/sections/Post';
import Layout from "../components/layout";
import Seo from "../components/seo";

import { composePosts } from '../utils';

const BlogPosts = ({ data: { markdownRemark: post, allMarkdownRemark: allPosts } }) => {
  const { frontmatter, html } = post;

  const postData = {
    title: frontmatter.title,
    date: frontmatter.date,
    body: html,
    img: frontmatter.image.childImageSharp.gatsbyImageData,
    author: {
      ...frontmatter.author.frontmatter,
      avatar: frontmatter.author.frontmatter.avatar.childImageSharp.gatsbyImageData
    },
  };

  const filteredPosts = composePosts(allPosts.nodes, frontmatter.slug);
  const relatedArticles = [...filteredPosts];
  const recentPosts = [...filteredPosts];

  return (
    <LayoutBlog title={`${postData.title} | Blog`}>
      <Hero
        title="iPaaS Insights"
        description="Integration tutorials, tips and best practices"
      />
      <PostViewer
        data={postData}
        recentPosts={recentPosts}
        relatedArticles={relatedArticles}
      />
    </LayoutBlog>
  );
};

const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 720)
          }
        }
        slug
        author {
          frontmatter {
            name
            role
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 72)
              }
            }
          }
        }
      }
    }

    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {type: {eq: "blog"}}}
      limit: 6
    ) {
      nodes {
        frontmatter {
          type
          title
          slug
          date(formatString: "MMMM DD")
          image {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          author {
            frontmatter {
              name
              role
              avatar {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 56)
                }
              }
              slug
            }
          }
        }
      }
    }
  }
`;

export { pageQuery };

export default BlogPosts;
